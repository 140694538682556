//import patternImage from "../img/pattern.png";
import React, { useEffect, useRef } from "react";
import "./Login.css"; // Import the CSS file for styling
import logoImage from "../img/abrechnungsystemLogos.jpg";

import { FaUser, FaLock } from "react-icons/fa";
import Globe from "@mui/icons-material/Language";
import {
  getBrowser,
  getCountryName,
  getDeviceType,
  getIPAddress,
  getLocalDateTime,
  getOperatingSystem,
  getScreenResolution,
  getUserDateFormat,
  getUserLanguage,
  getViewportDimensions,
} from "../../Common/UserSystemInformation";
import { useNavigate } from "react-router-dom";
import { Post } from "../../Common/WebRequest";
import { useState } from "react";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import CookieDialog from "./CookieDialog";
import {
  base64Encode,
  clearCacheData,
  generateRandomString,
} from "../../Common/Utility";
const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [userName, setUsername] = useState("");
  const [passwordUser, setPasswordUser] = useState("");

  const [info, setInfo] = useState(
    "Bitte geben Sie Benutzernamen und Passwort ein."
  );
  const [error, setError] = useState("");
  const [wait, setWait] = useState("");
  const [success, setSuccess] = useState("");
  const cookieAgree = JSON.parse(localStorage.getItem("cookieAgreement"));
  clearCacheData();
  useEffect(() => {
    (async () => {
      localStorage.removeItem("login");
    })();
  }, []);

  async function handleUsernameChange(event) {
    setUsername(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPasswordUser(event.target.value);
  };

  async function handleSubmit(event) {
    if (!cookieAgree || cookieAgree.isAgree === "") {
      setError(
        "Leider können Sie das System nicht betreten, da Sie der Verwendung von 'Cookies' nicht zugestimmt haben Bitte aktualisieren Sie die Seite und drücken Sie die OK-Schaltfläche, um fortzufahren."
      );
      return;
    }
    event.preventDefault();
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsLoading(false);
    setError("");
    setWait("");
    if (userName === "" || passwordUser === "") {
      setError("'Benutzername' oder 'Passwort' dürfen nicht leer sein.");
      setWait("");
      return;
    }
    setWait("Bitte warten....");
    var operatingSys = getOperatingSystem();

    var ipaddress = await getIPAddress();

    var browser = getBrowser();

    //var localDateTime = getLocalDateTime();
    var countryName = await getCountryName();
    var password = generateRandomString(16) + passwordUser;
    var deviceType = getDeviceType();
    var screenResolution = getScreenResolution();
    var viewportDimensions = getViewportDimensions();
    var userLanguage = getUserLanguage();
    var userDateFormat = getUserDateFormat();

    let loginDto = {
      userName,
      password,
      operatingSys,
      ipaddress,
      browser,
      //localDateTime,
      countryName,
      deviceType,
      screenResolution,
      viewportDimensions,
      userLanguage,
      userDateFormat,
    };
    const json = JSON.stringify(loginDto);
    var dt10 = base64Encode(json);
    let res = await Post("Login/Verify", dt10);
    if (res.isSuccess) {
      setError("");
      setWait("");
      setSuccess("OK");
      // see also
      localStorage.setItem(
        "login",
        JSON.stringify({
          isLogin: true,
          token: res.token,
          refreshToken: res.refreshToken,
          userName: userName,
          userId: res.result.id,
          isAdmin: res.result.isAdmin,
          systemGroupName: res.systemGroupName,
          systemType: res.systemType,
          sessionId: res.sessionId,
          loginDataIdentifier: dt10,
        })
      );
      clearCacheData();
      navigate("/home");
      navigate(0);

      // window.location.href = "/";
    } else {
      console.log("Error: " + res.Error + ", " + res.ErrorDetail);
      if (res.Error.toLowerCase().includes("failed to fetch")) {
        setError("Bitte überprüfen Sie die Verbindung zum Server.");
      } else setError(res.Error);
      //setError("'Benutzername' oder 'Passwort' ist falsch!");
      setUsername("");
      setPasswordUser("");
      setWait("");
    }
  }
  return (
    <div className="login-container">
      <div className="form-group">
        <div className="form-title">
          <img
            src={logoImage}
            alt="Willkommen zu AbrechnungsSystem"
            className="logo"
          />
        </div>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label
              htmlFor="username"
              className={error ? "labelValidation" : "labelStandard"}
            >
              <FaUser className="inputIcon" />
              Benutzername
            </label>
            <input
              autoFocus
              type="text"
              id="username"
              name="username"
              className="input"
              value={userName}
              onChange={handleUsernameChange}
            />
          </div>
          <div className="input-group">
            <label
              htmlFor="password"
              className={error ? "labelValidation" : "labelStandard"}
            >
              <FaLock className="inputIcon" />
              Passwort
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="input"
              value={passwordUser}
              onChange={handlePasswordChange}
            />
          </div>
          <button type="submit" disabled={isLoading} className="button">
            {isLoading ? (
              <ClipLoader
                color="white" // Set the color of the loading animation
                loading={isLoading}
                css={css`
                  display: block;
                  margin: 0 auto;
                `}
              />
            ) : (
              "Anmelden"
            )}
          </button>
          <label
            htmlFor="information"
            className={`labelInfo ${error ? "labelError" : ""} ${
              wait ? "labelWarning" : ""
            }`}
          >
            {error ? error : wait ? wait : info}
          </label>

          <br></br>
          <div>
            <p>
              <a href="passwordrecovery"> Passwort vergessen?</a>
            </p>
          </div>
          <br></br>
          <div className="flexContainer">
            <Globe className="inputIcon" />
            <p className="paragraph">
              Das System verwendet Standard-Cookies, um den Systembetrieb lokal
              abzuwickeln.
            </p>
          </div>
        </form>
      </div>
      <CookieDialog />
      <div className="footer-no">
        <p>© 2023 AbrechnungSystem. Alle Rechte vorbehalten.</p>
        <p>Developed By Mohammed Abujayyab</p>
      </div>
    </div>
  );
};

export default Login;
